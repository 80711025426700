import Loader from '../Loader'
import { ButtonBadge, ButtonWrapper } from './Button.styles'

const Button = ({ children, block, variant = 'primary', onClick, badge, size, isLoading }) => {
  return (
    <ButtonWrapper block={block} variant={variant} size={size} onClick={onClick}>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          {children}
          {badge && <ButtonBadge>{badge}</ButtonBadge>}
        </>
      )}
    </ButtonWrapper>
  )
}

export default Button
