import styled, { css } from 'styled-components'

import { Cover, Reset, TransitionPrimary } from '@/lib/mixins'

import { ButtonWrapper } from '../Button/Button.styles'

export const ModalBackdrop = styled.div`
  ${Cover()}
  background-color: rgba(0,0,0, 0.2);
`

export const ModalHeader = styled.h3`
  text-align: center;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  > *:last-child {
    margin-bottom: 0;
  }
`

export const ModalBody = styled.div`
  h2 {
    margin-bottom: 2rem;
  }
  > * {
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const ModalDialog = styled.div`
  position: relative;
  width: 32rem;
  padding: 3.25rem 2.5rem 2.5rem;
  background-color: var(--color-body);
  border-radius: 0;
  color: var(--color-global);
  overflow: hidden;

  ${props =>
    props.variant === 'confirmation' &&
    css`
      text-align: center;
      ${ModalBody} {
        img {
          width: 10rem;
          margin-bottom: 2rem;
        }
        h3 {
          font-size: 1.1rem;
        }

        ${ButtonWrapper} {
          min-width: 8.5rem;
          &:not(:last-child) {
            margin-right: 0.25rem;
          }
        }
      }
    `};

  ${props =>
    props.variant === 'default' &&
    css`
      text-align: center;
      ${ModalBody} {
        img {
          width: 24rem;
          margin-bottom: 1.5rem;
        }

        table {
          width: 15rem;
          margin: 0 auto;
          border: 1px solid var(--color-line);
          border-spacing: 0;
          font-size: 0.9rem;
          th {
            border-bottom: 1px solid var(--color-line);
            text-transform: uppercase;
            font-size: 0.6rem;
            padding: 0.5em;
            letter-spacing: 0.1em;
            color: var(--color-inverted);
            background-color: var(--color-global);
            &:not(:last-child) {
              border-right: 1px solid var(--color-line-inverted);
            }
          }

          tr,
          th {
            td {
              &:first-child {
                background: var(--color-light);
                font-weight: 400;
              }
              &:not(:last-child) {
                border-right: 1px solid var(--color-line);
              }
            }
          }

          tr {
            td {
              padding: 0.35em 0.5em;
            }
            &:not(:last-child) {
              td {
                border-bottom: 1px solid var(--color-line);
              }
            }
          }
        }
      }
    `};
`

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  bottom: 0;
  z-index: 150;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.25rem;
  overflow: auto;
`

export const ModalClose = styled.div`
  ${Reset('button')}
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  width: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.5rem;
  ${TransitionPrimary('transform')}

  svg {
    fill: currentColor;
    display: block;
    width: 40%;
    height: 40%;
  }

  &:hover {
    transform: rotate(90deg);
  }
`

export const ModalFooter = styled.div`
  padding-top: 1.75rem;
`
