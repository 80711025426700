import styled from 'styled-components'

import { Reset } from '@/lib/mixins'

export const NavBarWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: var(--size-nav-bar);
  padding: 2rem var(--view-padding-horizontal) 0;
  -webkit-tap-highlight-color: transparent;
`

export const NavBarSearch = styled.div`
  position: relative;
  width: 14rem;
  svg {
    position: absolute;
    top: 0.2rem;
    left: 0;
    width: 1.15rem;
    height: 1.15rem;
  }
  input {
    ${Reset('all')}
    width: 100%;
    padding-bottom: 1rem;
    padding-top: 0.2rem;
    text-transform: uppercase;
    padding-left: 1.75rem;

    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
    }

    &::placeholder {
      color: var(--color-global);
    }
    border-bottom: 1px solid var(--color-global);
  }
`

export const NavBarLogo = styled.div`
  position: relative;
  flex: 0 0 auto;
  height: 2.5rem;
  margin-right: auto;

  svg {
    height: 3rem;
    display: block;
    width: auto;
  }
`

export const NavBarBack = styled.button`
  ${Reset('button')}
  position: absolute;
  margin: 0 calc(var(--view-padding-horizontal) * 0.2);
  left: 0;
  width: calc(var(--view-padding-horizontal) * 0.6);
  padding: 0.2rem 0;
  display: flex;
  justify-content: center;

  svg {
    margin-left: -0.5em;
    height: 2.5rem;
    width: 2.5rem;
  }

  path {
    fill: none;
    stroke-width: 1.5;
  }
`

export const NavBarCart = styled.button`
  ${Reset('button')}
  position: absolute;
  margin: 0.2rem calc(var(--view-padding-horizontal) * 0.2) 0;
  right: 0;
  width: calc(var(--view-padding-horizontal) * 0.6);
  padding: 1rem 0 0;
  display: flex;
  justify-content: center;
  height: 2.5rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  line-height: 1.65;
  svg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    display: block;
  }
`
