import axios from 'axios'

const ApiClient = axios.create({
  baseURL: 'https://api.bergregions.pl/api',
  headers: {
    'Screen-Authorization': `${
      window?.BtDevice?.getAuthorizationToken() ||
      'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJCQTpSQzpFTDpPTjpBTTo1MCIsImlhdCI6MTcwODk2OTI0NCwiZXhwIjoxNzA4OTcyODQ0fQ.NgA6nBxNOo88zyiEli0YdRP8-lIdP2FG5FZJvD_tZ-ohFqA_ICK7bEyigam8pfKJuxxJmfhEy73HEMyMSUvXXA'
    }`
  }
})

console.log(window?.BtDevice?.getAuthorizationToken())

const API = {
  getWeather: (lat, lon) => ApiClient.get(`/v1/weather?lat=${lat}&lon=${lon}`),
  generateOnlineReceipt: data => ApiClient.put('/v1/fiscalization/retail/createTransaction', data)
}

export default API
