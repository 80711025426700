import styled from 'styled-components'

export const ConfirmationWrapper = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const ConfirmationContent = styled.div`
  text-align: center;
  margin: auto;
  min-height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  h1 {
  }

  svg {
    width: 8rem;
    margin-bottom: 2rem;
  }
  button {
    + button {
      margin-left: 1rem;
    }
  }
`

export const ConfirmationFooter = styled.div`
  margin-top: auto;
`

export const ConfirmationGallery = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin-bottom: var(--view-padding-vertical);
  img {
    max-width: 40%;

    &:nth-child(2) {
      margin-top: 10%;
    }
  }
`
