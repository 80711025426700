import { useTranslation } from 'react-i18next'

import Modal from '@/components/Modal'

const ConfirmationReceiptModal = props => {
  const { onClose, image } = props
  const { t } = useTranslation()

  return (
    <Modal onClose={onClose} header={t('yourOnlineReceipt')}>
      <img src={`data:image/png;base64,${image}`} alt="..." />
    </Modal>
  )
}

export default ConfirmationReceiptModal
