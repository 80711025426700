import { AnimatePresence, motion } from 'framer-motion'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import Button from '@/components/Button'
import View from '@/layout/View'
import API from '@/lib/api'

import useCheckout from '../../hooks/useCheckout'
import { ConfirmationContent, ConfirmationGallery } from './Confirmation.styles'
import ConfirmationReceiptModal from './ConfirmationReceiptModal'

const Confirmation = () => {
  const { t } = useTranslation()
  const { cart, reset, paymentError, setCurrentStep } = useCheckout()
  const navigate = useNavigate()
  const [isReceiptLoading, setIsReceiptLoading] = useState(false)
  const [onlineReceipt, setOnlineReceipt] = useState(null)

  const resetCheckout = () => {
    reset()
    navigate('/')
  }

  const showOnlineRceipt = () => {
    setIsReceiptLoading(true)
    API.generateOnlineReceipt({
      items: cart.map(o => ({
        name: o.title,
        quantity: o.qty,
        price: o.price
      }))
    })
      .then(response => {
        setIsReceiptLoading(false)
        setOnlineReceipt(response.data)
      })
      .catch(error => {
        console.error('[API] generateOnlineReceipt error', error)
        setIsReceiptLoading(false)
      })
  }
  // const printConfirmation = () => {
  //   try {
  //     const data = cart.map(o => ({ name: o.title, amount: o.price * o.qty }))
  //     window.BtDevice.printInvoice(JSON.stringify(data))
  //   } catch (error) {
  //     console.error('[DEVICE] printInvoice error', error)
  //   }
  // }

  return (
    <View showHeader cover>
      <ConfirmationContent>
        {/* Success */}
        {!paymentError && (
          <>
            <ConfirmationGallery>
              {cart[0] && (
                <motion.img
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.4 }}
                  src={cart[0].imageUrl}
                  alt=""
                />
              )}
              {cart[1] && (
                <motion.img
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.4 }}
                  src={cart[1].imageUrl}
                  alt=""
                />
              )}
            </ConfirmationGallery>
            <h1>{t('paymentConfirmationTitle')}</h1>
            <h3>{t('paymentConfirmationDescription')}</h3>
            <div>
              <Button size="lg" onClick={() => showOnlineRceipt()} isLoading={isReceiptLoading}>
                {t('showOnlineReceipt')}
              </Button>
              <Button size="lg" onClick={() => resetCheckout()}>
                {t('finish')}
              </Button>
            </div>
          </>
        )}
        {/* Error */}
        {paymentError && (
          <>
            <svg width="139px" height="138px">
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(4.208529, 4.000000)" stroke="#E43232" strokeWidth="3">
                  <motion.line
                    x1="0"
                    y1="130.073547"
                    x2="130.073547"
                    y2="0"
                    initial={{ pathLength: 0 }}
                    animate={{ pathLength: 1 }}
                    transition={{
                      delay: 0.25,
                      duration: 0.75
                    }}
                  ></motion.line>
                  <motion.line
                    x1="0"
                    y1="130.073547"
                    x2="130.073547"
                    y2="0"
                    transform="translate(65.036774, 65.036774) scale(-1, 1) translate(-65.036774, -65.036774) "
                    initial={{ pathLength: 0 }}
                    animate={{ pathLength: 1 }}
                    transition={{
                      delay: 0.75,
                      duration: 0.75
                    }}
                  ></motion.line>
                </g>
              </g>
            </svg>
            <h1>{t('paymentErrorTitle')}</h1>
            <p>{paymentError}</p>
            <Button size="lg" onClick={() => setCurrentStep('CART')}>
              {t('back')}
            </Button>
          </>
        )}
      </ConfirmationContent>
      <AnimatePresence>
        {onlineReceipt && <ConfirmationReceiptModal image={onlineReceipt} onClose={() => setOnlineReceipt(null)} />}
      </AnimatePresence>
    </View>
  )
}

export default Confirmation
