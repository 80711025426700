import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import Button from '@/components/Button'
import withScanner from '@/features/Scanner/withScanner'
import View from '@/layout/View'
import { CURRENCY_SIGN } from '@/lib/helpers'

import useCheckout from '../../hooks/useCheckout'
import { CartEmpty, CartProviderWrapper, CartSummaryWrapper, CartTable } from './Cart.styles'
import CartItem from './CartItem'

const CartSummary = () => {
  const { cartTotal } = useCheckout()
  const { t } = useTranslation()

  return (
    <CartSummaryWrapper>
      <h3>{t('totalAmount')}</h3>
      <h2>
        {CURRENCY_SIGN} {cartTotal.toFixed(2)}
      </h2>
      <p>{t('amountDescription')}</p>
    </CartSummaryWrapper>
  )
}

const CartActions = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const { cartTotal, setPaymentError, setCurrentStep } = useCheckout()

  const startPayment = () => {
    setIsLoading(true)
    try {
      window.BtDevice.startPayment(cartTotal)
    } catch (error) {
      console.error('[DEVICE] startPayment error', error)
      setPaymentError('Device error occured...')
      setTimeout(() => {
        setCurrentStep('CONFIRMATION')
      }, 1000)
    }
  }

  return (
    <>
      <Button block onClick={startPayment} isLoading={!!isLoading}>
        {t('proceedToPayment')}
      </Button>
      <Button block variant="link" onClick={() => navigate('/navigation')}>
        {t('continueShopping')}
      </Button>
    </>
  )
}

const CartProvider = () => {
  let paymentProvider;

  try {
    paymentProvider = window.BtDevice?.getPaymentProviderLogo() || process.env.REACT_APP_PAYMENT_PROVIDER || 'defaultPaymentProvider';
  } catch (error) {
    return process.env.REACT_APP_PAYMENT_PROVIDER || null;
  }

  if (!paymentProvider) {
    return null;
  }

  return (
    <>
      Powered by
      <img src={`/img/payments/${paymentProvider}.svg`} alt="" />
    </>
  );
};

const Cart = () => {
  const { cart, setPaymentError, setCurrentStep } = useCheckout()
  const { t } = useTranslation()
  const navigate = useNavigate()

  if (cart.length === 0) {
    navigate('/')
  }

  useEffect(() => {
    if (window.BtApp) {
      window.BtApp.paymentFinished = (code, msg) => {
        console.log('[APP] paymentFinished', code, msg)
        if (Number(code) !== 0) {
          setPaymentError(msg)
        } else {
          setPaymentError(null)
        }
        setCurrentStep('CONFIRMATION')
      }
    }
  }, [])

  return (
    <View showHeader hasFooter footerContent={<CartSummary />} footerActions={<CartActions />} footerProvider={<CartProvider />}>
      <h2>{t('yourSummary')}</h2>
      {cart.length > 0 && (
        <CartTable>
          {cart.map(product => (
            <CartItem key={product.id} product={product} />
          ))}
        </CartTable>
      )}
      {cart.length === 0 && <CartEmpty>{t('cartIsEmpty')}</CartEmpty>}
    </View>
  )
}

export default withScanner(Cart)
