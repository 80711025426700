import { AnimatePresence } from 'framer-motion'

import Cart from './components/Cart'
import Confirmation from './components/Confirmation'
import useCheckout from './hooks/useCheckout'

const Checkout = () => {
  const { currentStep } = useCheckout()

  return (
    <AnimatePresence>
      {currentStep === 'CART' && <Cart key="CART" />}
      {currentStep === 'CONFIRMATION' && <Confirmation key="CONFIRMATION" />}
    </AnimatePresence>
  )
}

export default Checkout
